omment/* Font Styles */
html {
  overflow-y: hidden;
}

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?dfrewi');
  src:  url('fonts/icomoon.eot?dfrewi#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?dfrewi') format('truetype'),
    url('fonts/icomoon.woff?dfrewi') format('woff'),
    url('fonts/icomoon.svg?dfrewi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-grid_icon:before {
  content: "\e900";
  color: #515151;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  color: #333333;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.albumDetailLeft {
  flex: 0 0 50%;
}

div.item-tooltip:hover {
  background-color: lightgrey;
  border-radius: 20px;
  color: black;
}

div.item-refresh {
  padding: 0px 5px 0px 3px;
  display: flex;
  align-items: center;
}

div.item-refresh:hover {
  background-color: lightgrey;
  border-radius: 20px;
  color: black;
}

div.item-trash {
  padding: 0px 5px 0px 3px;
  display: flex;
  align-items: center;
}

div.item-trash:hover {
  background-color: lightgrey;
  border-radius: 20px;
  color: black;
}

.summary {
  max-height: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
}

/* Box Styles */
.box {
  position: relative;
  display: flex;
  flex: 1 7%;
  background: black;
  max-width: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px; */
  transition: all 0.3s ease;
  border-radius: 20px;
  margin: 1.25rem;
  padding: 0px;
  overflow: hidden;
  align-self: center;
  --s: 150px; /* control the size */
  object-fit: cover;
  transition: 0.35s linear;
  height: max-content;
}

.box_folder {
  cursor: pointer;
  /*position: absolute;*/
  display: inline-block;
  top: 5px;
  /* right: 5px; */
  z-index: 10;
  display: flex;
  flex: 1 1 7%;
  background: #f1f5f8;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  /* margin-bottom: 10px; */
  /* transition: all 0.3s ease; */
  border-radius: 20px;
  margin: 10px;
  /* margin-right: 2rem; */
  padding: 0px;
  overflow: hidden;
  /* min-width: 150px; */
  /* width: 100%; */
  height: auto;
  align-self: center;
  /* --s: 150px; */
  height: 40px;
  width: -webkit-fill-available;
  /* object-fit: cover; */
  background-color: #f1f5f8;
  /* transition: .35s linear;*/
}

.box_folderN {
  cursor: pointer;
  /* position: absolute; */
  display: inline-block;
  top: 5px;
  /* right: 5px; */
  z-index: 10;
  display: flex;
  flex: 1 1 7%;
  background: #f1f5f8;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  /* margin-bottom: 10px; */
  /* transition: all 0.3s ease; */
  border-radius: 20px;
  margin: 10px;
  /* margin-right: 2rem; */
  padding: 0px;
  overflow: hidden;
  min-width: 150px;
  /* width: 100%; */
  height: auto;
  align-self: center;
  /* --s: 150px; */
  height: 40px;
  width: -webkit-fill-available;
  /* object-fit: cover; */
  background-color: #f1f5f8;
  /* transition: .35s linear;*/
}

.box_folder:active,
.box_folder:focus {
  /* styles de l'animation */
  transform: scale(0.9);
}

.box:hover {
  transform: scale(0.9);
  filter: none;
}

.box:active,
.box:focus {
  /* styles de l'animation */
  transform: scale(0.9);
  filter: grayscale(80%);
  background-color: #272626;
}

.box a {
  width: 100%;
}

.box div {
  width: 100%;
  /* height: 45px; */
  display: flex;
  flex: column;
  height: 65px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f1f5f8;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.box img {
  width: 100%;
  height: auto;
  /* border-radius: 6px;*/
}

.box h3 {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #4a5568;
}

.box span {
  line-height: 0;
}

.box p {
  line-height: 1.4rem;
  text-align: center;
  border-radius: 8px;
  /* box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); */
  color: #4a5568;
  /* padding: 9px; */
  background-color: #f1f5f8;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Selected Box Style */
.box.selected {
  background-color: #2c5282;
  color: #ffffff;
}

.box.selected h3 {
  color: #ffffff;
}

.box.selected p {
  color: #ffffff;
}

/* Board Styles */
.board {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.board-left {
  flex: 0 0 240px;
  max-width: 240px;
  background-color: #cbd5e0;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  height: 100vh;
  z-index: 1;
  transform: translateX(0);
  transition: transform 0.3s ease;
}

.board-left.is-active {
  transform: translateX(0);
  /* position: inherit; */
  position: absolute;
}

.board-right {
  flex: 1;
  display: block;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  background-color: black;
  height: 100vh;
  position: relative;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.board-right-grid {
  display: grid;
  overflow: auto;
  align-content: flex-start;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: var(--gap);
  margin: 0 0 var(--gap) 0;
  justify-items: center;
}

.board-right-grid-small {
  display: grid;
  overflow: auto;
  align-content: flex-start;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: var(--gap);
  margin: 0 0 var(--gap) 0;
  /* justify-items: center; */
}

.board-right-grid-folders {
  display: grid;
  overflow: auto;
  align-content: flex-start;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: var(--gap);
  margin: 0 0 var(--gap) 0;
  justify-items: center;
}

.board-right-grid-folders-small {
  display: grid;
  overflow: auto;
  align-content: flex-start;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: var(--gap);
  margin: 0 0 var(--gap) 0;
  justify-items: center;
}

.popup {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  background-color: #fff;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 80%;
  max-width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  height: 400px;
}

/* Style pour le bouton de fermeture de la popup */
.close {
  color: #aaa; /* Couleur de texte pour le bouton de fermeture */
  float: right; /* Aligner le bouton de fermeture à droite */
  font-size: 28px; /* Taille de police pour le bouton de fermeture */
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-card-body.custom-modal {
  max-height: 400px;
  min-height: 400px;
  overflow-y: auto; /* Ajouter une barre de défilement verticale en cas de dépassement de la hauteur maximale */
}

.newcollection {
  padding: 20px;
}

.item {
  padding: 10px 20px 10px 20px;
  overflow: hidden;
}

.itemsList {
  overflow: auto;
  height: 67%;
}

.itemsList p {
  padding: 20px;
}

.item:hover {
  background-color: darkslategray;
  color: white;
}

.selected {
  background-color: #aaa;
  padding: 10px 20px 10px 20px;
}

.popupdelete {
  position: absolute;
  top: 5px;
  right: 5px;
}

.media_content_albums_list {
  padding: 15px;
}

.burger-menu {
  display: none; /* caché par défaut */
  z-index: 10;
}

.fade-in-10 {
  opacity: 0;
  animation: fadeIn-10 0.2s ease-in-out forwards;
}

.fade-in-30 {
  animation: fadeIn-30 0.4s ease-in-out forwards;
}

@keyframes fadeIn-30 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn-10 {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.2;
  }
}

/* Media query for smartphones */
@media (max-width: 767px) {
  .box {
    max-width: 150px; /* 400px / 2 */
    margin: 0.625rem; /* 1.25rem / 2 */
    border-radius: 10px; /* 20px / 2 */
    /* You may need to adjust other properties based on your specific requirements */
  }
  .box_folder {
    display: none;
  }

  .top_bar_right {
    padding-left: 40px;
  }
  
  .burger-menu {
    display: flex; /* Utilisez flex pour centrer l'icône à l'intérieur du bouton */
    justify-content: center;
    align-items: center;
    position: fixed; /* Position absolue par rapport au viewport */
    top: 10px; /* Ajustez en fonction de vos besoins */
    left: 10px; /* Ajustez en fonction de vos besoins */
    z-index: 1000; /* Assurez-vous qu'il est au-dessus des autres éléments */

    width: 40px; /* La taille totale du bouton */
    height: 40px; /* La taille totale du bouton */
    border-radius: 50%; /* Rend le bouton rond */

    background-color: white; /* Couleur de fond du bouton */
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2); /* Ombre portée pour un effet de profondeur */
    cursor: pointer;
  }
  .board-left {
    transform: translateX(-100%);
    position: fixed;
    z-index: 1;
    /* top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;     */
  }
  .board-left.is-active {
    /* Le menu s'affiche lorsque 'is-active' est ajouté */
    transform: translateX(0);
    min-width: 0px;
  }

  .board-right-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .board-right-grid-folders {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .modal-card {
    width: 90%;
  }
}
